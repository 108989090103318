import React, { FC, useMemo } from 'react';
import { StyledCategoriesContainer } from './styled';
import CategoryMenu from '../CategorySlider/CategorySlider';
import { TCategoryPickerItem } from '../CategoryPickerItem/CategoryPickerItem';
import { useInfiniteHits, useInstantSearch } from 'react-instantsearch';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import { useCategories } from '~/features/checkout/hooks/useCategories';
import PageHero, { PageHeroProps } from '~/templates/pages/components/PageHero/PageHero';
import { UmbracoAlgoliaCategories } from '~/lib';

type BasePLPHeroProps = Omit<PageHeroProps, 'headline' | 'theme' | 'imageAltText'> & {
    heroHeader?: string; // headline
    heroTheme?: 'Light' | 'Dark'; // theme
    heroImageAltText?: string; // imageAltText
    categories?: TCategoryPickerItem[];
};

const BasePLPHero: FC<BasePLPHeroProps> = ({
    heroHeader,
    heroTheme,
    heroImageAltText,
    categories,
    ...props
}) => (
    <PageHero theme={heroTheme} headline={heroHeader} imageAltText={heroImageAltText} {...props}>
        <StyledCategoriesContainer>
            {!!categories?.length && <CategoryMenu categories={categories} />}
        </StyledCategoriesContainer>
    </PageHero>
);

export type HeroWithBaseCategoriesProps = BasePLPHeroProps & {
    baseCategories?: Record<string, string>;
    isLoadingBaseCategories: boolean;
};

const ProductListHero: FC<HeroWithBaseCategoriesProps> = ({
    baseCategories,
    isLoadingBaseCategories,
    categories,
    belowText,
    ...props
}) => {
    const { hits, results } = useInfiniteHits<TProductDetails>({ escapeHTML: false });
    const { status } = useInstantSearch();

    const categoriesToUse = useMemo(() => {
        if (categories?.length) return categories;

        if (!baseCategories || isLoadingBaseCategories) return undefined;

        const productCategories = [...new Set(hits.map((item) => item.primary_category_id))];
        const searchedCategoryArray = [];

        for (const [, key] of Object.entries(productCategories)) {
            if (key && baseCategories && key in baseCategories) {
                searchedCategoryArray.push({
                    isActive: false,
                    link: {
                        title: key,
                        url: baseCategories[key],
                    },
                });
            }
        }

        return searchedCategoryArray;
    }, [categories, baseCategories, hits]);

    const shouldShowBelowText =
        status !== 'loading' && !!results?.query && belowText && !hits.length;

    return (
        <BasePLPHero
            {...props}
            belowText={shouldShowBelowText ? belowText : undefined}
            categories={categoriesToUse}
        />
    );
};

export type PLPHeroProps = BasePLPHeroProps & {
    dataType?: keyof typeof UmbracoAlgoliaCategories;
};

const PLPHero: FC<PLPHeroProps> = ({ dataType, ...props }) => {
    const isBrandPage = dataType === 'Brand Id';
    const { data, isLoading: isLoadingCategoryList } = useCategories({
        // Only fetch categories list if we don't get a "categories" prop.
        enabled: !isBrandPage && !props.categories?.length,
    });

    const heroProps: HeroWithBaseCategoriesProps = Object.assign({}, props, {
        baseCategories: data,
        isLoadingBaseCategories: isLoadingCategoryList,
    });

    if (isBrandPage) {
        /**
         * On Brand pages, we will not show tiles in hero
         */
        return <BasePLPHero {...props} />;
    }

    return <ProductListHero {...heroProps} />;
};

export { PLPHero };
