import { useRouter } from 'next/router';
import React from 'react';
import PLPModule from '~/features/productList/PLPModule';
import { Modules, P40ProductListPage } from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';
import { useFrame } from '~/shared/utils/frame';

type P40ProductListPageProps = P40ProductListPage;

export const PLPPage = (props: P40ProductListPageProps) => {
    const {
        pageElements,
        bottomPageElements,
        dataConfiguration,
        heroImage,
        heroImageAltText,
        categories,
        data,
        ...rest
    } = props;

    const { query } = useRouter();
    const { data: frameData } = useFrame();
    const pageSize = frameData?.productSettings?.pageSize || 20;

    return (
        <>
            <PLPModule
                hitsPerPage={pageSize}
                filters={dataConfiguration}
                image={heroImage}
                heroImageAltText={heroImageAltText}
                serverState={data?.plpResult ?? undefined}
                categories={categories?.filter((c) => c.link.title)}
                query={query}
                {...rest}
            >
                <DynamicBlockList elements={pageElements as Modules[]} />
            </PLPModule>
            <DynamicBlockList elements={bottomPageElements as Modules[]} />
        </>
    );
};
