import Head from 'next/head';
import { memo, useMemo } from 'react';
import { useHits } from 'react-instantsearch';
import { useFrame } from '~/shared/utils';

const PLPSchema = () => {
    const { hits } = useHits({ escapeHTML: false });
    const { data: frameData } = useFrame();

    const innerHTML: { __html: string } | undefined = useMemo(() => {
        if (!hits) return undefined;

        const preparedHits = hits.map((item: any, index) => ({
            '@type': 'ListItem',
            position: index,
            item: {
                '@type': 'Product',
                productID: item?.id,
                sku: item?.EAN,
                name: item?.name,
                url: item?.url,
                brand: item?.brand,
                description: item?.short_description,
                image: item?.image_groups[0]?.images[0]?.dis_base_link,
                offers: {
                    '@type': 'Offer',
                    price: item?.price[frameData?.market?.currency?.currency as string],
                    priceCurrency: Object.keys(item?.price).toString(),
                },
            },
        }));

        const schemaObject = {
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            itemListElement: preparedHits,
        };

        return { __html: JSON.stringify(schemaObject) };
    }, [hits]);

    // https://www.hillwebcreations.com/valid-image-size-json-ld-snippet-markup/#:~:text=%E2%80%9CImages%20must%20be%20at%20least,most%201920%C3%971080%20pixels.
    return (
        <Head>
            <script type="application/ld+json" dangerouslySetInnerHTML={innerHTML} />
        </Head>
    );
};

export default memo(PLPSchema);
