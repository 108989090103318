import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '~/shared/utils/request/utils/fetcher';

type QueryOptions<DataType> = Omit<UseQueryOptions<DataType, Error>, 'queryKey' | 'queryOptions'>;

export const useCategories = <DataType extends Record<string, string>>(
    options?: QueryOptions<DataType>,
) => {
    return useQuery<DataType, Error>({
        ...options,
        queryKey: ['/api/proxy/categories/list'],
        queryFn: fetcher<DataType>('/api/proxy/categories/list'),
    });
};
