import { breakpoints } from '~/theme/breakpoints';
import styled from '@emotion/styled';

export const StyledCategoriesContainer = styled.div(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    [breakpoints.sm]: {
        bottom: theme.space[6],
    },
}));
